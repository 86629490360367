import * as React from "react"
import Layout from "../components/layout"

const ApparelPage = () => (
  <Layout title={"Apparel"}>
    <p>Apparel grid</p>
  </Layout>
)

export default ApparelPage
